<template>
  <div>
    <div class="">
      <div class="row align-items-center mb-3">
        <div class="col">
          <h4 class="mt-0 header-title"><span class="md-body-2"></span></h4>
          <!-- <p class="text-muted font-14 mb-0">trails</p> -->
        </div>

        <div class="">
          <div class="col-auto">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a
                  href="#"
                  :class="['nav-link', { active: isActive('list') }]"
                  @click.prevent="setActiveTab('list'), clearFields()"
                >
                  List
                </a>
              </li>
              <li class="nav-item" v-if="add && !isExperimentLocked">
                <a
                  href="#"
                  :class="['nav-link', { active: isActive('create') }]"
                  @click.prevent="setActiveTab('create')"
                >
                  Create
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'list'">
      <div class="row align-items-center mb-3">
        <!-- <div class="col">
          <label for="movie" class="custom-input-sm"
            >Show
            <select name="" class="form-control" id="">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="">All</option>
            </select></label
          >
        </div> -->

        <div class="col-md-6">
          <!-- <label for="movie" class="mt-2 font-weight-bold">
            Trial No: {{ trialNo }}</label
          > -->

          <!-- <label class="md-body-2 mt-4 mr-3">Trial Number:</label> -->

          <!-- <select
            class="form-control width-10 ml-2 pl-2"
            v-model="trialNoDrp"
            @change="fetchFeedFormulaList()"
          >
            <option v-for="item in trialNoList" :key="item.id" :value="item.id">
              {{ item.trial_no }}
            </option>
          </select> -->
        </div>

        <!-- <div>
          <md-button type="button" @click="onEditPrevious()"
            >Load previous experiment data
          </md-button>
        </div> -->


        <div class="col-md-6 text-right">

          <button
          v-if="!disableLoadPrev && !isExperimentLocked"         
           class="btn btn-sm btn-success mr-2"
            @click="openModal()"
          
          >
         Load previous experiment data
          </button>

          <button
            class="btn btn-sm btn-primary"
            :disabled="!feedFormulaListFiltered.length"
            @click="exportPDF"
          >
            Export to PDF
          </button>
        </div>

        <!-- <md-field class="custom-md-field" style="margin: 0px 0px 0px 0px">
          <label>Search...</label>
          <md-input
            v-model="search"
            @input="$debounce(onChangeFilter)"
          ></md-input>
        </md-field>
        <md-icon>search</md-icon> -->
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <!-- <md-card>
          <md-card-header data-background-color="green">
            <h4 class="title">FORMULA BY USING CONCENTRATE listtt</h4>
          </md-card-header>
          <md-card-content>
            <table class="styled-tablex">
              <thead>
                <tr class="first-option">
                  <th>Sl No</th>
                  <th>Ingredients</th>
                  <th>Pre Starter</th>
                  <th>Starter</th>
                  <th>Finisher</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in temp" :key="item.id">
                  <td>
                    {{ item.slNo }}
                  </td>
                  <td>
                    <template v-if="!item.isEditing">{{
                      item.ingredients
                    }}</template>
                    <template v-else>
                      <select v-model="item.ingredients" class="slct2">
                        <option value="Soya">Soya</option>
                        <option value="Maize">Maize</option>
                        <option value="7.5StarterPremix">
                          7.5 Starter Premix
                        </option>
                        <option value="OIL">OIL</option>
                      </select>
                    </template>
                  </td>

                  <td>
                    <template v-if="!item.isEditing">{{
                      item.prestarter
                    }}</template>
                    <template v-else>
                      <input v-model="item.prestarter" class="custm" />
                    </template>
                  </td>
                  <td>
                    <template v-if="!item.isEditing">{{
                      item.starter
                    }}</template>
                    <template v-else>
                      <input v-model="item.starter" class="custm" />
                    </template>
                  </td>
                  <td>
                    <template v-if="!item.isEditing">{{
                      item.finisher
                    }}</template>
                    <template v-else>
                      <input v-model="item.finisher" class="custm" />
                    </template>
                  </td>

                  <td>
                    <button v-if="!item.isEditing" @click="toggleEdit(item)">
                      <md-icon>edit_square</md-icon>
                    </button>
                    <button v-else @click="saveChanges(item)">
                      <md-icon>save</md-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </md-card-content>
        </md-card> -->
        <div>
          <md-card
            v-for="group in feedFormulaListFiltered"
            :key="group.trial_group_id"
          >
            <md-card-header data-background-color="green">
              <h4 class="title">
                {{ "FORMULA BY USING CONCENTRATE FOR " + group.trial_group_id }}
              </h4>
            </md-card-header>

            <md-card-content>
              <md-table
                :table-header-color="tableHeaderColor"
                class="custom-table"
              >
                <tr>
                  <th class="py-2">Sl No</th>
                  <th class="py-2">Ingredients</th>
                  <th class="py-2">Pre Starter</th>
                  <th class="py-2">Starter</th>
                  <th class="py-2">Finisher</th>
                </tr>

                <!-- <tr
                v-for="(item, index) in feedFormulaList?.data"
                :key="item.id"
              > -->

                <tr v-for="(item, index) in group?.data" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.ingredient_name }}</td>
                  <td>{{ !item.prestarter ? "--" : item.prestarter }}</td>
                  <td>{{ !item.starter ? "--" : item.starter }}</td>
                  <td>{{ !item.finisher ? "--" : item.finisher }}</td>
                </tr>

                <tr v-if="group.data.length">
                  <td></td>
                  <td class="font-weight-bold">Total</td>
                  <td class="font-weight-bold">
                    {{
                      showFeedCategoryTotal(group, "prestarter") 
                    }}
                  </td>
                  <td class="font-weight-bold">
                    {{ showFeedCategoryTotal(group, "starter") }}
                  </td>
                  <td class="font-weight-bold">
                    {{ showFeedCategoryTotal(group, "finisher") }}
                  </td>
                </tr>

                <tr v-if="group.data.length && !disableEditButton">
                  <td></td>
                  <td></td>
                  <td>
                    <button
                      @click="onEdit('prestarter', group.trial_group_id)"
                      v-if="showFeedCategoryTotal(group, 'prestarter') && !isExperimentLocked"
                    >
                      <i class="mdi mdi-pencil mx-auto"></i>
                    </button>
                  </td>
                  <td>
                    <button
                      @click="onEdit('starter', group.trial_group_id)"
                      v-if="showFeedCategoryTotal(group, 'starter')&& !isExperimentLocked"
                    >
                      <i class="mdi mdi-pencil mx-auto"></i>
                    </button>
                  </td>
                  <td>
                    <button
                      @click="onEdit('finisher', group.trial_group_id)"
                      v-if="showFeedCategoryTotal(group, 'finisher')&& !isExperimentLocked"
                    >
                      <i class="mdi mdi-pencil mx-auto"></i>
                    </button>
                  </td>
                </tr>
              </md-table>
            
            </md-card-content>
            <!-- <div class="row px-3">
            <div class="col-md-6">
              Showing
              {{ feedFormulaList.from ? feedFormulaList.from : 0 }}
              to
              {{ feedFormulaList.to ? feedFormulaList.to : 0 }} of
              {{ feedFormulaList.total ? feedFormulaList.total : 0 }}
              entries
            </div>
            <div class="col-md-6">
              <pagination
                :pagination="feedFormulaList"
                :offset="4"
                @paginate="onPaginate"
              ></pagination>
            </div>
          </div> -->
          </md-card>
          <loading-bar v-if="load"></loading-bar>
        </div>
      </div>
    </div>

    <div v-if="activeTab === 'create'">
      <form class="form-style" @submit.prevent="submit()">
        <div style="width: 75%">
          <md-card>
            <md-card-header data-background-color="green">
              <h4 class="title">FEED FORMULA USING CONCENTRATE</h4>
            </md-card-header>

            <md-card-content class="px-5">
              <div class="row">
                <div class="col-md-4 d-flex text-nowrap">
                  <label class="md-body-2 mt-4 mr-3">Trial Number:</label>
                  <md-field>
                    <!-- <label for="country">Select</label> -->
                    <!-- <md-field> -->
                    <md-input v-model="trialNo" readonly></md-input>
                    <!-- </md-field> -->
                  </md-field>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-5">
                  <div class="d-flex text-nowrap">
                    <label class="md-body-2 mt-4 mr-3">Start Date:</label>
                    <!-- <md-datepicker v-model="startDate" md-immediately>
                            <label>Select date</label>
                          </md-datepicker> -->

                    <md-field>
                      <md-input v-model="startDate" readonly></md-input>
                    </md-field>
                  </div>

                  <validation :errors="errors" field="start_date"></validation>
                </div>

                <div class="col-md-4">
                  <div class="d-flex text-nowrap">
                    <label class="md-body-2 mt-4 mr-3">End Date:</label>

                    <md-field>
                      <md-input v-model="endDate" readonly></md-input>
                    </md-field>
                  </div>

                  <validation :errors="errors" field="end_date"></validation>
                </div>
              </div>

              <div class="row mt-3">
                <!-- //trial group T1, T2... -->

                <div class="col-md-5">
                  <div class="d-flex text-nowrap">
                    <label class="md-body-2 mt-4 mr-3">Trial Group:</label>

                    <!-- <div class="dropdown">
      <button 
        class="btn btn-outline-secondary dropdown-toggle" 
        type="button" 
        id="dropdownMenuButton" 
        data-toggle="dropdown" 
        aria-haspopup="true" 
        aria-expanded="false"
      >
        Select Trial Groups
      </button>

      <div class="dropdown-menu p-3" aria-labelledby="dropdownMenuButton">
        <div v-for="item in trialGroupList" :key="item.id" class="form-check">
          <input 
            type="checkbox" 
            class="form-check-input" 
            :value="item.id" 
            v-model="selectedTrialGroups" 
            @change="clearErrors"
          />
          <label class="form-check-label">{{ item.trial_group_id }} ({{ item.trial_group_name }})</label>
        </div>
      </div>
    </div> -->

                    <div class="form-group w-100 mt-3">
                      <multi-select
                       
                        id="id1"
                        :filters="trialGroupList"
                        :selected_items="selectedTrialGroups"
                        @selected="
                          (value) => {
                            selectedTrialGroups = value;
                            validateTrialGroups();
                          }
                        "
                        searchBy="trial_group_id"
                        label="trial_group_id"
                      ></multi-select>

                      <!-- <md-field v-else>
                        <md-input v-model="trialGroupText" readonly></md-input>
                      </md-field> -->

                      <div class="mt-2">
                        <p v-if="errorMsg" class="text-danger">
                          {{ errorMsg }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="d-flex text-nowrap">
                    <label class="md-body-2 mt-4 mr-3"> Feed Category:</label>

                    <select
                      v-if="!isUpdate"
                      class="form-control my-auto"
                      v-model="chickCategoryId"
                      @change="
                        clearErrors();
                        validateTrialGroups();
                      "
                    >
                      <option
                        v-for="item in chickCategoryList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.category_name }}
                      </option>
                    </select>

                    <md-field v-else>
                      <md-input v-model="categoryName" readonly></md-input>
                    </md-field>
                  </div>

                  <validation
                    :errors="errors"
                    field="chick_category"
                  ></validation>
                </div>
              </div>
              <hr class="mb-2" />

              <div
                v-for="(row, index) in selectedIngredientList"
                :key="index"
                class="row"
              >
                <div class="col-md-5">
                  <div class="d-flex text-nowrap">
                    <label class="md-body-2 mt-4 mr-3"
                      >Select Ingredients</label
                    >

                    <select
                      class="form-control mt-4"
                      v-model="row.ingredient_id"
                      @change="
                        fetchIngredientValue(row.ingredient_id, index),
                          (ingredientValidationMsg = '')
                      "
                    >
                      <!-- <option selected disabled value="">select</option> -->
                      <option
                        v-for="item in ingredientList"
                        :key="item.id"
                        :value="item.id"
                      >
                        {{ item.ingredients }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="d-flex text-nowrap">
                    <label class="md-body-2 mt-4 mr-3">Qty</label>

                    <md-field>
                      <label>Quantity</label>
                      <md-input
                        class="mt-2"
                        v-model="row.quantity"
                        type="number"
                        @input="updateTotalWeight, (quantityValidationMsg = '')"
                      ></md-input>
                    </md-field>
                    <h9 class="ml-2 mt-4">kg</h9>
                    <div
                      @click="removeRow(row)"
                      class="my-auto mx-2"
                      style="cursor: pointer"
                      v-if="selectedIngredientList.length > 1"
                    >
                      <md-icon>clear</md-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5">
                  <p class="text-danger">{{ ingredientValidationMsg }}</p>
                </div>
                <div class="col-md-4">
                  <p class="text-danger">{{ quantityValidationMsg }}</p>
                </div>
              </div>

              <hr />
              <div class="row mt-3">
                <div class="col-md-9">
                  <md-button @click="addRow" class="md-primary"
                    >Add Row</md-button
                  >
                </div>

                <div class="col-md-3">
                  <div class="d-flex text-nowrap">
                    <label class="md-body-2 mt-4 mr-3">Total</label>
                    <md-field>
                      <label>Total</label>
                      <md-input
                        v-model="totalWeight"
                        type="number"
                        disabled
                      ></md-input>
                    </md-field>
                  </div>

                  <p class="text-danger">{{ weightValidationMsg }}</p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 text-right">
                  <md-button
                    v-if="!isUpdate"
                    type="submit"
                    class="md-success mr-2"
                    :disabled="disableSubmitButton"
                    >{{ requestStatus ? "Saving..." : "Submit" }}</md-button
                  >

                  <md-button
                    v-else
                    @click="update()"
                    type="button"
                    class="md-success mr-2"
                    :disabled="disableSubmitButton"
                    >{{ requestStatus ? "Updating..." : "Update" }}</md-button
                  >

                  <md-button
                    type="button"
                    @click="clearFields(), setActiveTab('list')"
                    class="md-danger"
                    >Cancel</md-button
                  >
                </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </form>
    </div>
    <md-dialog :md-active.sync="isModalActive">
                <md-dialog-title>Verify & Submit</md-dialog-title>
                <md-dialog-content>
                  Are you sure you want to submit previous experiment data?
                </md-dialog-content>
                <md-dialog-actions>
                  <md-button class="md-primary" :disabled="prevStoreStatus" @click="onEditPrevious()"
                    >{{prevStoreStatus ? 'Saving..':'Yes, Submit'}}</md-button
                  >
                  <md-button class="md-secondary" @click="closeModal"
                    >Cancel</md-button
                  >
                </md-dialog-actions>
              </md-dialog>
  </div>
</template>
  
  <script>
import LoadingBar from "../components/LoadingBar.vue";
import MultiSelect from "../components/MultiSelect.vue";
import jsPDF from "jspdf";
import "jspdf-autotable";
export default {
  components: { LoadingBar, MultiSelect },

  name: "feedFormula",

  props: {
    tableHeaderColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      trialNoList: [],
      chickCategoryList: [],
      chickCategoryId: "",

      trialGroupList: [],
      // trialGroupId: "",

      selectedTrialGroups: [],

      search: "",
      trialNo: "",
      trialNoDrp: "",

      ingredientList: [],

      expInfoId: "",
      startDate: "",
      endDate: "",

      categoryName: "",

      totalWeight: 0, // Initialize total weight
      weightValidationMsg: "",
      ingredientValidationMsg: "",
      quantityValidationMsg: "",

      activeTab: "list", // Default active tab
      errors: {},

      feedFormulaList: [],

      selectedIngredientList: [
        { ingredient_id: "", quantity: "" }, // Initial row
      ],
      requestStatus: false, // disable submit button if submit request loading
      prevStoreStatus: false, // disable prev data button if submited once

      load: false, // for loader
      isUpdate: false, // for update button
      trialGroupText: "",
      errorMsg: "",

      add: false,
      disableLoadPrev:true,
      disableEditButton:true,

      isModalActive: false,
      isExperimentLocked:true

    };
  },

  mounted() {
    this.add = this.$store.getters.getActiveSideBar.permissions[0].add;
    this.$material.locale.dateFormat = "dd-MM-yyyy";
    this.disableModification()
    this.fetchFeedFormulaList();
    this.getTrialNoList();
    // this.fetchChickCategory();
    this.fetchIngredients();
    // this.fetchFeedFormulaList();
  },

  computed: {
    disableSubmitButton() {
      let length = this.selectedIngredientList.length;
      let lastItem = this.selectedIngredientList[length - 1];

      return lastItem.ingredient_id == "" ||
        !lastItem.quantity ||
        this.weightValidationMsg ||
        this.requestStatus ||
        this.errorMsg
        ? true
        : false;
    },

    showPrestarter() {
      return 1; //this.feedFormulaList.data.some((item) => item.prestarter);
    },
    showStarter() {
      return 1; //this.feedFormulaList.data.some((item) => item.starter);
    },
    showFinisher() {
      return 1; // this.feedFormulaList.data.some((item) => item.finisher);
    },
    feedFormulaListFiltered() {
      const mergeValues = (existing, duplicate) => {
        // Merge prestarter, starter, and finisher if one of them is null
        existing.prestarter = existing.prestarter || duplicate.prestarter;
        existing.starter = existing.starter || duplicate.starter;
        existing.finisher = existing.finisher || duplicate.finisher;
      };

      // Use a map to track unique groups based on data content
      const uniqueGroups = new Map();

      // Iterate over each group in feedFormulaList
      this.feedFormulaList.forEach((group) => {
        if (!group.data || group.data.length === 0) {
          uniqueGroups.set(`empty-${group.trial_group_id}`, group);
          return; // Skip merging logic and store the group separately
        }

        // Create a map to store unique ingredients for the current group
        const seenIngredients = new Map();

        // Filter duplicates within the group's data based on ingredient_id
        const filteredData = group.data.filter((item) => {
          // Check if ingredient_id already exists in the seenIngredients map
          if (seenIngredients.has(item.ingredient_id)) {
            // If it exists, merge the values (prestarter, starter, finisher) but prevent duplicate names
            const existingItem = seenIngredients.get(item.ingredient_id);
            mergeValues(existingItem, item);
            return false; // Skip this duplicate item
          } else {
            // Add the new ingredient to the map
            seenIngredients.set(item.ingredient_id, item);
            return true;
          }
        });

        // Create a unique key by serializing the filtered data
        const groupKey = JSON.stringify(
          filteredData.map((item) => ({
            ingredient_id: item.ingredient_id,
            prestarter: item.prestarter,
            starter: item.starter,
            finisher: item.finisher,
          }))
        );

        // If the groupKey already exists in uniqueGroups, merge trial_group_id
        if (uniqueGroups.has(groupKey)) {
          const existingGroup = uniqueGroups.get(groupKey);
          existingGroup.trial_group_id += `, ${group.trial_group_id}`;
        } else {
          // If it's a unique group, add it to the map
          uniqueGroups.set(groupKey, {
            ...group,
            data: filteredData, // Use the filtered data with unique ingredient names
          });
        }
      });

      // Convert the Map back into an array
      return Array.from(uniqueGroups.values());
    },
  },
  watch: {
    startDate: function (value) {
      this.clearErrors();
      this.calculateEndDate();
    },

    endDate: function (value) {
      this.clearErrors();
    },

    selectedIngredientList: {
      handler() {
        this.updateTotalWeight();
      },
      deep: true,
    },
  },

  methods: {
    showFeedCategoryTotal(arr, key) {
      // return arr.data.some((item) => item[key]);
      return arr.data.reduce((sum, item) => {
        return sum + Number(item[key] || 0);
      }, 0);
    },

    openModal() {
      this.isModalActive = true;
      this.prevStoreStatus = false;
      // this.requestStatus=true;
    },
    closeModal() {
      this.isModalActive = false;
      this.prevStoreStatus = false;
      // this.requestStatus=false;
    },

    exportPDF() {
      const doc = new jsPDF();
      let currentY = 20; // Initial Y position for the first table
      let pageNumber = 1; // Initialize page number
      let trialNumber = this.$store.getters.getSelectedTrial.trial_no;
      // Function to add the page number
      function addPageNumber() {
        const pageWidth = doc.internal.pageSize.width;
        doc.text(
          `Page ${pageNumber}`,
          pageWidth - 30,
          doc.internal.pageSize.height - 10
        );
      }

      // Function to add the "Trial no" text at the top of the page
      function addTrialText() {
        doc.setFontSize(10); // Set font size to 10 for this text

        doc.text(`Trial no: ${trialNumber}`, 14, 10); // X = 14, Y = 10 for positioning at the top
      }

      // Add the "Trial no:" line at the top of the first page
      addTrialText();
      // Set the font size back to normal
      doc.setFontSize(12);

      this.feedFormulaListFiltered.forEach((group, groupIndex) => {
        // Define the table columns (just the headers as strings)
        const tableColumns = [
          "Sl No",
          "Ingredients",
          "Pre Starter",
          "Starter",
          "Finisher",
        ];

        // Map the data for the table rows
        const tableRows = group.data.map((item, index) => [
          index + 1, // Sl No
          item.ingredient_name, // Ingredients
          item.prestarter || "--", // Pre Starter
          item.starter || "--", // Starter
          item.finisher || "--", // Finisher
        ]);

        // Add total row
        tableRows.push([
          "", // Empty Sl No
          "Total", // Ingredients
          this.showFeedCategoryTotal(group, "prestarter"), // Pre Starter Total
          this.showFeedCategoryTotal(group, "starter"), // Starter Total
          this.showFeedCategoryTotal(group, "finisher"), // Finisher Total
        ]);

        // Check if the table fits on the current page
        if (
          currentY + 10 + doc.autoTable.previous.finalY >
          doc.internal.pageSize.height - 20
        ) {
          // Add page number for the current page
          addPageNumber();

          // Add a new page
          doc.addPage();
          pageNumber++; // Increment page number
          currentY = 20; // Reset Y position for the new page

          // Add the "Trial no:" text at the top of the new page
          addTrialText();
        }

        // Add title for each group
        doc.text(
          `FORMULA BY USING CONCENTRATE FOR ${group.trial_group_id}`,
          14,
          currentY
        );

        // Add the table using autoTable
        doc.autoTable({
          head: [tableColumns], // Use array of strings as headers
          body: tableRows, // Use array of arrays for table rows
          startY: currentY + 10, // Adjust starting position for each table
          theme: "grid",
          headStyles: {
            fillColor: [0, 57, 107], // Set header background color to blue
            textColor: [255, 255, 255], // Set header text color to white
          },
          margin: { bottom: 20 }, // Add margin to avoid overlap with page number
        });

        // Update currentY to move below the table for the next one
        currentY = doc.lastAutoTable.finalY + 20; // Move 20 units below the last table
      });

      // Add page number for the last page
      addPageNumber();

      // Save the PDF
      doc.save("feed_formula.pdf");
    },

    // showFeedCategoryTotal(group, category) {
    //   // Implement your logic to calculate totals here
    //   return true;
    // },
    
    calculateEndDate() {
      if (this.startDate) {
        // Convert startDate from DD-MM-YYYY to YYYY-MM-DD
        const [day, month, year] = this.startDate.split("-").map(Number);
        const formattedStartDate = `${year}-${month}-${day}`;

        // Create Date object from formattedStartDate
        const startDate = new Date(formattedStartDate);
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 35); // Adding 36 days

        // Format endDate as DD-MM-YYYY
        const dayEnd = endDate.getDate().toString().padStart(2, "0");
        const monthEnd = (endDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        const yearEnd = endDate.getFullYear();

        this.endDate = `${dayEnd}-${monthEnd}-${yearEnd}`;
      }
    },

    isActive(tab) {
      return this.activeTab === tab;
    },
    addRow() {
      let length = this.selectedIngredientList.length;
      let lastItem = this.selectedIngredientList[length - 1];
      if (lastItem.ingredient_id == "") {
        this.ingredientValidationMsg = "please select ingredient";
      }
      if (!lastItem.quantity) {
        this.quantityValidationMsg = "please enter quantity";
      }
      if (lastItem.ingredient_id == "" || !lastItem.quantity) {
        return;
      }
      this.selectedIngredientList.push({ quantity: "", ingredient_id: "" });
    },

    removeRow(row) {
      this.selectedIngredientList = this.selectedIngredientList.filter(
        (i) => i !== row
      );

      this.updateTotalWeight(); // Update total weight after removing a row
      this.ingredientValidationMsg = "";
      this.quantityValidationMsg = "";
    },

    setActiveTab(tab) {
      this.activeTab = tab;
      if (tab == "list") this.isUpdate = false;
      // here--
    },

    toggleEdit(item) {
      item.isEditing = !item.isEditing;
    },
    saveChanges(item) {
      item.isEditing = false;
      // Additional logic to save changes if needed
    },
    onChangeFilter(page = 1) {
      // page number
      // this.experimentInfoList.current_page = page;

      this.fetchFeedFormulaList();
    },

    getFeedFormulaInfo() {
      axios
        .get(
          `/indous-research/feed-formula-concentrate/get-feed-formula-info?exp_info_id=${this.expInfoId}&chick_category=${this.chickCategoryId}`
        )
        .then((response) => {
          this.trialGroupText = response.data.trialGroupText;
          // this.requestStatus = false;
          // this.$notify({
          //   message: response.data.message,
          //   horizontalAlign: "right",
          //   verticalAlign: "top",
          //   type: "success",
          // });
          // this.clearFields();
          // this.setActiveTab("list");
        })
        .catch((error) => {
          // this.requestStatus = false;
          // this.errors = error.response.data.errors;
        });
    },

    onEdit(category, trialGroupId) {
      // display saved data in fields

      if (category == "prestarter") {
        this.categoryName = "Pre Starter";
        this.chickCategoryId = 1;
      }

      if (category == "starter") {
        this.categoryName = "Starter";
        this.chickCategoryId = 2;
      }

      if (category == "finisher") {
        this.categoryName = "Finisher";
        this.chickCategoryId = 3;
      }
      this.setActiveTab("create");
      // auto select multi select
      // this.selectedTrialGroups = this.trialGroupList.fill
      // console.log('fs',trialGroupId);
      let arr = trialGroupId.split(', ');
      this.selectedTrialGroups = this.trialGroupList.filter(i=>arr.includes(i.trial_group_id));
      this.isUpdate = true;
      this.trialGroupText = trialGroupId;
      // this.getFeedFormulaInfo();
      this.filterSavedIngredients(category, trialGroupId);
      // this.id = item.id;
    },

    //   onEditPrevious() {
    // this.loading = true;
    // axios
    //   .get(`/indous-research/feed-formula-concentrate/get-previous-data`)
    //   .then((response) => {
    //     this.rawMaterials = response.data.map(rawItem => ({
    //       ingredient_id: rawItem.ingredient_id,
    //       pbs: rawItem.pbs,
    //       bs: rawItem.bs,
    //       bf1: rawItem.bf1

    // experiment_info_id: this.expInfoId,
    //         start_date: this.startDate,
    //         end_date: this.endDate,
    //         selected_trial_group_list: JSON.stringify(this.selectedTrialGroups),
    //         chick_category: this.chickCategoryId,
    //         selected_ingredient_list: JSON.stringify(this.selectedIngredientList),

    //     }));
    //     this.loading = false;
    //     if (this.rawMaterials.length === 0) {
    //     this.clear();
    //   }
    //   })
    //   .catch((error) => {
    //     this.loading = false;
    //     // console.error(error); // Log the error for debugging
    //   });
    disableModification()
    {
       axios.get('/indous-research/feed-formula-concentrate/dependanc-check').then(res=>{
        this.disableLoadPrev = res.data.isExist
        this.disableEditButton = res.data.isUsed
        this.isExperimentLocked = res.data.isExperimentLocked
       })
    },
    onEditPrevious() {
      this.prevStoreStatus = true;

      axios
        .post("/indous-research/feed-formula-concentrate/post-previous-data", {
          // experiment_info_id: this.expInfoId,
          // start_date: this.startDate,
          // end_date: this.endDate,
          // selected_trial_group_list: JSON.stringify(this.selectedTrialGroups),
          // chick_category: this.chickCategoryId,
          // selected_ingredient_list: JSON.stringify(this.selectedIngredientList),
        })
        .then((response) => {
          this.prevStoreStatus = false;

          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          // this.clearFields();
          // this.setActiveTab("list");
          this.fetchFeedFormulaList();
          this.disableModification();
          this.closeModal();
        })
        .catch((error) => {
          this.prevStoreStatus = false;
          // this.errors = error.response.data.errors;
        });
    },

    //////

    filterSavedIngredients(category, trialGroupId) {
      this.selectedIngredientList = [];
      let filterArray = this.feedFormulaListFiltered.filter(
        (i) => i.trial_group_id == trialGroupId
      );

      filterArray[0].data.forEach((i) => {
        if (i[category] > 0) {
          this.selectedIngredientList.push({
            ingredient_id: i.ingredient_id,
            quantity: i[category],
          });
        }
      });
      // console.log('sele',this.selectedIngredientList)
    },
    fetchIngredientValue(ingredientId, index) {
      axios
        .get(
          `/indous-research/feed-formula-concentrate/get-ingredient-quantity?ingredientId=${ingredientId}&trialGroupId=${JSON.stringify(
            this.selectedTrialGroups
          )}&chickCategory=${this.chickCategoryId}`
        )
        .then((response) => {
        if ( response.data.ingrdientQuantity) {
          this.selectedIngredientList[index].quantity =
          response.data.ingrdientQuantity;
        }

        });
    },
    update() {
      // if (this.totalWeight < 1000) {
      //   this.weightValidationMsg =
      //     "Total weight must be exactly 1000 kg. Please adjust the quantities accordingly.";
      //   return;
      // }

      if(this.selectedTrialGroups.length < 1){
         this.errorMsg = "Please select trial group"
         return;
      }

      this.requestStatus = true;
      axios
        .put("/indous-research/feed-formula-concentrate/" + this.$store.getters.getSelectedTrial.id, {
          chick_category: this.chickCategoryId,
          selected_ingredient_list: JSON.stringify(this.selectedIngredientList),
          selected_trial_groups: JSON.stringify(this.selectedTrialGroups.map((i)=>i.id)),
        })
        .then((response) => {
          this.requestStatus = false;
          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.clearFields();
          this.setActiveTab("list");
        })
        .catch((error) => {
          this.requestStatus = false;
          this.errors = error.response.data.errors;
        });
    },

    clearErrors() {
      this.errorMsg = "";
      this.errors = {};
      this.ingredientValidationMsg = this.quantityValidationMsg = "";
    },

    clearFields() {
      // clear form fields after submit or update
      this.chickCategoryId = this.search = "";
      // this.trialNoDrp = this.trialNoList[0].id;
      this.selectedIngredientList = [{ ingredient_id: "", quantity: "" }];
      this.selectedTrialGroups = [];
      this.totalWeight = 0;
      this.trialGroupText = "";

      this.clearErrors();
      // this.filteredChickCategory();
      this.fetchChickCategory();
      this.fetchTrialGroup(); //added
      this.fetchFeedFormulaList();
      // this.feedFormulaList();
    },

    getTrialNoList() {
      // if (!this.startDate) return;

      axios
        .get(`/indous-research/experiment-information/get-trial-no-list`)
        .then((response) => {
          this.trialNoList = response.data.trialNoList;
          //  console.log("-h-h-h-h-h-", this.trialNoList);
          this.trialNo = this.trialNoList[0]?.trial_no;
          // this.trialNoDrp = this.trialNoList[0]?.id;
          // this.expInfoId = this.trialNoList[0]?.id;
          this.startDate = this.trialNoList[0]?.start_date;
          // this.filteredChickCategory();  //no need
          this.fetchChickCategory();
          this.fetchTrialGroup(); //added
          // this.fetchFeedFormulaList();
        });
    },
    validateTrialGroups() {
      if (this.isUpdate || !this.chickCategoryId || this.selectedTrialGroups.length < 1) return;
      axios
        .post(`/indous-research/feed-formula-concentrate/validate-allocation`, {
          selected_trial_groups: JSON.stringify(this.selectedTrialGroups),
          chick_category: this.chickCategoryId,
        })
        .then((response) => {
          this.errorMsg = response.data.message;
        })
        .catch((err) => {
          this.errorMsg = err.response.data.message;
          // console.error(err.response.data.message);
        });
    },
    fetchChickCategory() {
      //no need?
      axios.get(`/indous-research/master/chick-category`).then((response) => {
        this.chickCategoryList = response.data.chickCategoryList;
      });
    },

    // filteredChickCategory() { //this no need
    //   axios
    //     .get(
    //       `/indous-research/feed-formula-concentrate/get-filter-category?experiment_info_id=${this.expInfoId}`
    //     )
    //     .then((response) => {
    //       this.chickCategoryList = response.data.chickCategoryList;
    //     });
    // },

    fetchTrialGroup() {
      axios
        .get(`/indous-research/experiment-information/get-trial-groups`)
        .then((response) => {
          this.trialGroupList = response.data.trialGroups;
        });
    },

    //top ka

    fetchIngredients() {
      axios
        .get(`/indous-research/master/feedIngredient-master`)
        .then((response) => {
          this.ingredientList = response.data.feedIngredientList;
        });
    },

    updateTotalWeight() {
      this.totalWeight = this.selectedIngredientList.reduce((total, row) => {
        const quantity = parseFloat(row.quantity);
        return total + (isNaN(quantity) ? 0 : quantity);
      }, 0);
      if (this.totalWeight > 1000) {
        this.weightValidationMsg =
          "Total weight exceeds 1000 kg. Please adjust the quantities.";
      } else {
        this.weightValidationMsg = "";
      }
      // this.quantityValidationMsg = ''
    },

    fetchFeedFormulaList() {
      this.load = true;
      this.feedFormulaList = [];
      axios
        // .get(
        //   `/indous-research/feed-formula-concentrate?search=${this.search }&paginate=${this.paginate}&page=${this.feedFormulaList.current_page}`
        // )

        .get(
          `/indous-research/feed-formula-concentrate?exp_info_id=${this.trialNoDrp}&search=${this.search}`
        )
        .then((response) => {
          this.feedFormulaList = response.data.feedFormulaList;
          // console.log('Feed Formula List -- ',this.feedFormulaList);

          this.load = false;
        });
    },

    submit() {
      // if (this.totalWeight < 1000) {
      //   this.weightValidationMsg =
      //     "Total weight must be exactly 1000 kg. Please adjust the quantities accordingly.";
      //   return;
      // }

      this.requestStatus = true;

      axios
        .post("/indous-research/feed-formula-concentrate", {
          // experiment_info_id: this.expInfoId,
          start_date: this.startDate,
          end_date: this.endDate,
          selected_trial_group_list: JSON.stringify(this.selectedTrialGroups),
          chick_category: this.chickCategoryId,
          selected_ingredient_list: JSON.stringify(this.selectedIngredientList),
        })
        .then((response) => {
          this.requestStatus = false;

          this.$notify({
            message: response.data.message,
            horizontalAlign: "right",
            verticalAlign: "top",
            type: "success",
          });
          this.disableModification()
          this.clearFields();
          this.setActiveTab("list");
        })
        .catch((error) => {
          this.requestStatus = false;
          this.errors = error.response.data.errors;
        });
    },
  },
};
</script>
  
  <style scoped>
.md-field {
  margin: 0px 0px;
}

hr {
  border: none;
  height: 2px;
  background-color: #000;
  margin-top: 25px;
  margin-bottom: 0rem;
}

.custom-md-field {
  width: 190px;
}

.row {
  --ct-gutter-x: 1.5rem;
  --ct-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--ct-gutter-y));
  margin-right: calc(-0.5 * var(--ct-gutter-x));
  margin-left: calc(-0.5 * var (--ct-gutter-x));
}

.tab-content {
  padding: 20px 0 0 0;
  border: none;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #71b6f9 !important;
}

.align-items-center {
  align-items: center !important;
  padding: 0px 65px 0px 20px;
}

.mb-3 {
  margin-bottom: 1.5rem !important;
}

.col {
  flex: 10 0%;
}

.mt-0 {
  margin-top: 0 !important;
}

.header-title {
  font-size: 1rem;
  margin: 0 0 7px 0;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

label {
  display: block;
  margin-bottom: 5px;
}

button[type="submit"],
button[type="button"].cancel-button {
  cursor: pointer;
}

#buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: rgb(113, 182, 249);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 30px;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.form-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}
</style>
  