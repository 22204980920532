<template>

    <!-- trial(experiment) selection -->
    <div>
        <!-- <div class="form-container">
            <div class="form-groupx">
                <label for="trial-no">Select Trial No</label>
                <select id="trial-no" v-model="selectedTrial" @change="fetchTrialGroups()">
                    <option v-for="trial in trials" :key="trial.id" :value="trial.id">
                    {{ trial.trial_no }}
                    </option>
                </select>
            </div>
        </div> -->

        <div class="row align-items-start mb-3 mt-3 form-container">
            <div v-if="bodyWeightPerBird.length > 0 && showDownloadButton">
                <button @click="downloadPDF" type="button" class="btn btn-sm btn-success">
                    <md-icon class="text-white">download</md-icon> Download PDF
                </button>
            </div>
        </div>
        
        <div v-if="loadingMain">
            <LoadingBar />
        </div>
        <div v-else-if="!this.trials">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="red">
                            <div class="section-title text-center">Experiment details not found</div>
                        </md-card-header>
                        
                    </md-card-content>
                </md-card>
            </div>
        </div>
        <div v-else-if="!this.trialGroupList.length > 0">
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="red">
                            <div class="section-title text-center">No trial group data found for the given experiment info ID</div>
                        </md-card-header>
                        
                    </md-card-content>
                </md-card>
            </div>
        </div>
        
        <div v-else ref="pdfContent">

            <!-- Body Wt Per Bird in Kgs -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Body Wt Per Bird in Kgs</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Age in Days</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bodyWt in bodyWeightPerBird" :key="bodyWt.age_in_days_or_week">
                                    <td class="font-weight-bold">{{ bodyWt.age_in_days_or_week }}</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="bodyWt.data.find(bodyWtData => bodyWtData.trial_group_id === trial.id)">
                                            {{ bodyWt.data.find(bodyWtData => bodyWtData.trial_group_id === trial.id).total_avg_body_wt_bird }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="bodyWtData in bodyWt.data" :key="bodyWtData.trial_group_id">{{ bodyWtData ? bodyWtData.total_avg_body_wt_bird : '-' }}</td>
                                    <template v-if="bodyWt.data.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - bodyWt.data.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                    
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>



            <!-- Feed Consumption Per Bird in Kgs -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Feed Consumption Per Bird in Kgs</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Age in Days</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="feedConsumed in feedConsumptionPerBird" :key="feedConsumed.age_in_days_or_week">
                                    <td class="font-weight-bold">{{ feedConsumed.age_in_days_or_week }}</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="feedConsumed.data.find(feedConsumedData => feedConsumedData.trial_group_id === trial.id)">
                                            {{ feedConsumed.data.find(feedConsumedData => feedConsumedData.trial_group_id === trial.id).total_feed_consumed_per_bird }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="feedConsumedData in feedConsumed.data" :key="feedConsumedData.trial_group_id">{{ feedConsumedData ? feedConsumedData.total_feed_consumed_per_bird : '-' }}</td>
                                    <template v-if="feedConsumed.data.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - feedConsumed.data.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>
            


            <!-- Day Wise FCR -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Day Wise FCR</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Age in Days</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="fcr in dayWiseFcrData" :key="fcr.age_in_days_or_week">
                                    <td class="font-weight-bold">{{ fcr.age_in_days_or_week }}</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="fcr.data.find(fcrData => fcrData.trial_group_id === trial.id)">
                                            {{ fcr.data.find(fcrData => fcrData.trial_group_id === trial.id).fcr }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="fcrData in fcr.data" :key="fcrData.trial_group_id">{{ fcrData ? fcrData.fcr : '-' }}</td>
                                    <template v-if="fcr.data.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - fcr.data.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>



            <!-- Day 35 Result Summary -->
            <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
                <md-card>
                    <md-card-content>
                        <md-card-header data-background-color="green">
                            <h4 class="title">Day 35 Result Summary</h4>
                        </md-card-header>
                        <div class="section-title"></div>
                        <div v-if="loading"><LoadingBar></LoadingBar></div>
                        <table class="custom-table" v-else>
                            <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th v-for="trial in trialGroupList" :key="trial.id">{{ 'T' + trial.id }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="font-weight-bold">Bodyweight / Bird (Kg)</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35BodyWeight.find(day35BodyWt => day35BodyWt.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35BodyWeight.find(day35BodyWt => day35BodyWt.trial_group_id === trial.id).total_avg_body_wt_bird) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(day35BodyWt, index) in day35BodyWeight" :key="index">{{ day35BodyWt ? formatDecimalPoints(day35BodyWt.total_avg_body_wt_bird) : '-' }}</td>
                                    <template v-if="day35BodyWeight.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35BodyWeight.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->

                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Feed Consumed / Bird (Kg)</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35FeedConsumption.find(feedData => feedData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35FeedConsumption.find(feedData => feedData.trial_group_id === trial.id).total_feed_consumed_per_bird) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(feedData, index) in day35FeedConsumption" :key="index">{{ feedData ? formatDecimalPoints(feedData.total_feed_consumed_per_bird) : '-' }}</td>
                                    <template v-if="day35FeedConsumption.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35FeedConsumption.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">FCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35Fcr.find(fcrData => fcrData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35Fcr.find(fcrData => fcrData.trial_group_id === trial.id).fcr) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(fcrData, index) in day35Fcr" :key="index">{{ fcrData ? formatDecimalPoints(fcrData.fcr) : '-' }}</td>
                                    <template v-if="day35Fcr.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35Fcr.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">CFCR</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="cfcrDay35.find(cfcrData => cfcrData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(cfcrDay35.find(cfcrData => cfcrData.trial_group_id === trial.id).cfcr) }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(cfcrData, index) in cfcrDay35" :key="index">{{ cfcrData ? formatDecimalPoints(cfcrData.cfcr) : '-' }}</td>
                                    <template v-if="cfcrDay35.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - cfcrDay35.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Livability (%)</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="livabilityPercentage.find(livabilityData => livabilityData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(livabilityPercentage.find(livabilityData => livabilityData.trial_group_id === trial.id).livability, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(livabilityData, index) in livabilityPercentage" :key="index">{{ livabilityData ? formatDecimalPoints(livabilityData.livability, 'fixTo2') : '-' }}</td>
                                    <template v-if="livabilityPercentage.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - livabilityPercentage.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">BEI</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="day35Bei.find(beiData => beiData.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(day35Bei.find(beiData => beiData.trial_group_id === trial.id).bei, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(beiData, index) in day35Bei" :key="index">{{ beiData ? formatDecimalPoints(beiData.bei, 'fixTo2') : '-' }}</td>
                                    <template v-if="day35Bei.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - day35Bei.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">Feed Cost / Kg</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="feedCostData.find(feedCost => feedCost.trial_group_id === trial.id)">
                                            {{ feedCostData.find(feedCost => feedCost.trial_group_id === trial.id).cost }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(feedCost, index) in feedCostData" :key="index">{{ feedCost ? feedCost.cost : '-' }}</td>
                                    <template v-if="feedCostData.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - feedCostData.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                                <tr>
                                    <td class="font-weight-bold">FD Cost/K.g Live Bird wt</td>
                                    <td v-for="trial in trialGroupList" :key="trial.id" class="font-weight-bold">
                                        <span v-if="feedCostPerLiveBirdWt.find(feedCostLiveBird => feedCostLiveBird.trial_group_id === trial.id)">
                                            {{ formatDecimalPoints(feedCostPerLiveBirdWt.find(feedCostLiveBird => feedCostLiveBird.trial_group_id === trial.id).cost, 'fixTo2') }}
                                        </span>
                                        <span v-else>-</span>
                                    </td>
                                    <!-- <td class="font-weight-bold" v-for="(feedCostLiveBird, index) in feedCostPerLiveBirdWt" :key="index">{{ feedCostLiveBird ? formatDecimalPoints(feedCostLiveBird.cost, 'fixTo2') : '-' }}</td>
                                    <template v-if="feedCostPerLiveBirdWt.length < trialGroupList.length">
                                        <td v-for="trial in (trialGroupList.length - feedCostPerLiveBirdWt.length)" :key="trial.id">{{ '-' }}</td>
                                    </template> -->
                                </tr>
                            </tbody>
                        </table>
                    </md-card-content>
                </md-card>
            </div>

        <!-- <md-button type="submit" class="md-success">Submit</md-button> -->
        </div>
    </div>
</template>
<script>
    import jsPDF from 'jspdf';
    import html2canvas from 'html2canvas';
    import LoadingBar from '../components/LoadingBar.vue';
    export default {
        components: {
            LoadingBar,
        },
        data(){
            return {
                showDownloadButton: false,
                selectedTrial: null,
                trialGroupList: [],
                loadingMain: false,
                loading: false,
                trials: [],
                bodyWeightPerBird: [],
                feedConsumptionPerBird: [],
                dayWiseFcrData: [],
                day35BodyWeight: [],
                day35FeedConsumption: [],
                day35Fcr: [],
                cfcrDay35: [],
                livabilityPercentage: [],
                feedCostData: [],
                feedCostPerLiveBirdWt: [],
                day35Bei: [],
                no_of_birds_remaining: [],
                no_of_birds_alloted: [],
            }
        },
        mounted(){
            this.trials = this.$store.getters.getSelectedTrial;
            this.selectedTrial = this.trials.id
            // this.fetchTrials();
            if(this.selectedTrial){
                this.fetchTrialGroups();
            }
        },
        watch: {
            no_of_birds_remaining(){
                this.day35Summary();
            },
            no_of_birds_alloted(){
                this.day35Summary();
            },
            // selectedTrial(){
            //     this.fetchTrialGroups();
            //     this.fetchBodyWeightFeedConsumedPerBirdFcr();
            // }
        },
        methods: {
            // fetchTrials() {
            //     this.loadingMain = true;
            //     axios.get('/indous-research/master/trials')
            //         .then((response) => {
            //             this.trials = response.data;
            //             if (this.trials.length > 0) {
            //                 this.selectedTrial = this.trials[0].id;
            //                 this.fetchTrialGroups();
            //             }
            //         })
            //         .catch((error) => {
            //             this.loadingMain = false;
            //             this.trials = [];
            //             this.$notify({
            //                 message: 'Experiment details not found',
            //                 horizontalAlign: 'right',
            //                 verticalAlign: 'top',
            //                 type: 'danger'
            //             });
            //         //   console.error('Error fetching trials:', error);
            //         });
            // },
            async fetchTrialGroups(){
                this.loadingMain = true;
                this.clearDay35Data();
                this.clearData();
                if(this.selectedTrial != null){
                    try {
                        const response = await axios.get(`indous-research/master/trial-group-list?experiment_info_id=${this.selectedTrial}`);
                        this.trialGroupList = response.data;
                        this.fetchBodyWeightFeedConsumedPerBirdFcr();
                        this.loadingMain = false;
                    } catch (error) {
                        this.loadingMain = false;
                        this.trialGroupList = [];
                        this.$notify({
                            message: error.response.data.message || 'An error occured',
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'danger'
                        });
                    }
                }
            },

            clearDay35Data(){
                this.day35BodyWeight = [];
                this.day35FeedConsumption = [];
                this.day35Fcr = [];
                this.cfcrDay35 = [];
                this.livabilityPercentage = [];
                this.feedCostData = [];
                this.feedCostPerLiveBirdWt = [];
                this.day35Bei = [];
            },

            clearData(){
                this.bodyWeightPerBird = [];
                this.feedConsumptionPerBird = [];
                this.dayWiseFcrData = [];
            },

            formatDecimalPoints(value, key) {
                if (value !== null && value !== undefined && value !== "") {
                    return Number(value) % 1 === 0
                    ? Number(value).toFixed(0)
                    : key ? Number(value).toFixed(2): Number(value).toFixed(3);
                }
                return "";
            },

            async fetchBodyWeightFeedConsumedPerBirdFcr() {
                this.loading = true;
                this.clearData();
                try {
                    const response = await axios.get(`/indous-research/get-average-body-weight-feed-consumed-fcr/${this.selectedTrial}`);
                    this.bodyWeightPerBird = response.data.bodyWtResult;
                    this.feedConsumptionPerBird = response.data.feedConsumedResult;
                    this.dayWiseFcrData = response.data.fcrData;
                    this.fetchBirdCountAndFeedCostData();
                    this.day35Summary();
                    this.loading = false;
                } catch (error) {
                    this.loading = false;
                }
            },
            async fetchBirdCountAndFeedCostData(){
                this.no_of_birds_remaining = [];
                this.no_of_birds_alloted = [];
                try {
                    const response = await axios.get(`/fetch-bird-count-details?expId=${this.selectedTrial}`);
                    this.no_of_birds_remaining = response.data.remainingBirds;
                    this.no_of_birds_alloted = response.data.allotedBirds;
                } catch (error) {
                    
                }
            },
            day35Summary(){
                this.clearDay35Data();
                this.showDownloadButton = false;
                if(this.bodyWeightPerBird){
                    this.bodyWeightPerBird.forEach(bodyWeight => {
                        if(bodyWeight.age_in_days_or_week == 35){
                            this.day35BodyWeight = bodyWeight.data;
                        }
                    })
                }

                if(this.feedConsumptionPerBird){
                    this.feedConsumptionPerBird.forEach(feedData => {
                        if(feedData.age_in_days_or_week == 35){
                            this.day35FeedConsumption = feedData.data;
                        }
                    })
                }

                if(this.dayWiseFcrData){
                    this.dayWiseFcrData.forEach(fcr => {
                        if(fcr.age_in_days_or_week == 35){
                            this.day35Fcr = fcr.data;
                        }
                    })
                }

                if(this.day35BodyWeight && this.day35Fcr){
                    this.day35BodyWeight.forEach( day35bodyWtData => {
                        this.day35Fcr.forEach( day35fcrData => {
                            if(day35bodyWtData.trial_group_id == day35fcrData.trial_group_id){
                                const newObj = {};
                                newObj.cfcr = day35bodyWtData.total_avg_body_wt_bird && day35fcrData.fcr ? ((2 - Number(day35bodyWtData.total_avg_body_wt_bird)) * 0.2 + Number(day35fcrData.fcr)).toFixed(3) : 0;
                                newObj.trial_group_id = day35bodyWtData.trial_group_id;
                                this.cfcrDay35.push(newObj);
                            }
                        })
                    })
                }

                if(this.no_of_birds_alloted.length > 0 && this.no_of_birds_remaining.length){
                    this.no_of_birds_alloted.forEach( noOfBirdsAlloted => {
                        this.no_of_birds_remaining.forEach( noOfBirdsRemaining => {
                            if(noOfBirdsAlloted.trial_group_id == noOfBirdsRemaining.trial_group_id){
                                const newObj = {};
                                newObj.livability = noOfBirdsRemaining.no_of_birds_remaining && noOfBirdsAlloted.groupwise_bird_count ? ((Number(noOfBirdsRemaining.no_of_birds_remaining) * 100) / Number(noOfBirdsAlloted.groupwise_bird_count)).toFixed(3) : 0;
                                newObj.trial_group_id = noOfBirdsAlloted.trial_group_id;
                                this.livabilityPercentage.push(newObj);
                            }
                        })
                    })
                }

                if(this.no_of_birds_alloted.length > 0){
                    this.no_of_birds_alloted.forEach( noOfBirdsAlloted => {
                        const newObj = {};
                        newObj.cost = noOfBirdsAlloted.feed_cost ? noOfBirdsAlloted.feed_cost : 0; 
                        newObj.trial_group_id = noOfBirdsAlloted.trial_group_id;
                        this.feedCostData.push(newObj);
                    })
                }

                if(this.day35Fcr && this.feedCostData){
                    this.day35Fcr.forEach( fcrData => {
                        this.feedCostData.forEach( feedCost => {
                            if(fcrData.trial_group_id == feedCost.trial_group_id){
                                const newObj = {};
                                newObj.cost = fcrData.fcr && feedCost.cost ? (Number(fcrData.fcr) * Number(feedCost.cost)).toFixed(3) : 0;
                                newObj.trial_group_id = fcrData.trial_group_id;
                                this.feedCostPerLiveBirdWt.push(newObj);
                            }
                        })
                    })
                }

                if(this.day35BodyWeight && this.livabilityPercentage && this.day35Fcr){
                    this.day35BodyWeight.forEach( bodyWeight => {
                        this.livabilityPercentage.forEach( livability => {
                            this.day35Fcr.forEach( fcrData => {
                                if(bodyWeight.trial_group_id == livability.trial_group_id && livability.trial_group_id == fcrData.trial_group_id){
                                    const newObj = {};
                                    newObj.bei = bodyWeight.total_avg_body_wt_bird && livability.livability && fcrData.fcr ? Math.round((Number(bodyWeight.total_avg_body_wt_bird) * Number(livability.livability) * 100) / (35 * fcrData.fcr)) : 0;
                                    newObj.trial_group_id = fcrData.trial_group_id;
                                    this.day35Bei.push(newObj);
                                }  
                            })
                        })
                    })
                }
                
                this.showDownloadButton = true;
            },
            
            downloadPDF() {
                // this.loadingMain = true;
                const pdfContent = this.$refs.pdfContent;
                html2canvas(pdfContent, {scale: 4}).then((canvas) => {
                    const imgData = canvas.toDataURL('image/png', 1.0);
                    const pdf = new jsPDF('p', 'mm', 'a4');
                    const imgProps = pdf.getImageProperties(imgData);
                    const pdfWidth = pdf.internal.pageSize.getWidth();
                    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight, undefined, 'FAST');
                    pdf.save('Summary-report.pdf');
                    // this.loadingMain = false;
                });
            }

        }
    }
</script>
<style scoped>
    .custom-table {
    width: 100%;
    border-collapse: collapse;
    }
    .custom-table th,
    .custom-table td {
    padding: 8px;
    /* text-align: left; */
    border-bottom: 1px solid #ddd;
    width: 50px;
    }
    .custom-table th {
    background-color: #f5f5f5;
    font-size: 16px;
    }
    .custom-table .slct,
    .custom-table .custm {
    width: 100%;
    box-sizing: border-box;
    }
</style>